<template>
  <div>
    <v-row>
      <v-col cols="6">
        <span v-if="!isDialog && !isDialogStaff" class="headline">Staff Information</span>
      </v-col>
    </v-row>
    <v-card :class="cardClass">
      <h3 class="mb-2"><v-chip
        class="primary black--text mr-4"
        label
      >1</v-chip>Basic</h3>
        <v-row class="ml-4">
          <v-col cols="12" sm="4">
            <v-switch
              v-model="posSwitch"
              inset
              :label="'POS Status'"
            ></v-switch>
          </v-col>
          <v-col cols="12" sm="4">
            <v-switch
              v-model="employeeSwitch"
              inset
              :label="`Employee Status`"
            ></v-switch>
          </v-col>
          <v-col cols="12" sm="4">
            <v-switch
              v-model="flagSwitch"
              inset
              :label="`Flag Status`"
            ></v-switch>
          </v-col>
        </v-row>
        <v-form ref="form2" v-model="form2">
          <v-row>
            <v-col cols="12" md="12">
              <v-select
                v-model="staff.role_name"
                :items="role"
                label="Role"
                :rules="[v => !!v || 'Role is required']"
                :loading="isEdit && editLoading"
                :disabled="isEdit && editLoading || isDialogStaff"
                required
                ></v-select>
              <v-text-field
                v-model="staff.name"
                label="Name"
                type="text"
                :rules="nameRules"
                :loading="isEdit && editLoading"
                :disabled="isEdit && editLoading"
                required
              ></v-text-field>
              <v-text-field
                v-model="staff.address"
                label="Address"
                type="text"
                :rules="addressRules"
                :loading="isEdit && editLoading"
                :disabled="isEdit && editLoading"
                required
              ></v-text-field>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="staff.designation"
                    label="Designation"
                    type="text"
                    :rules="[v => !!v || 'Designation is required']"
                    :loading="isEdit && editLoading"
                    :disabled="isEdit && editLoading"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="staff.designation_type"
                    :items="designationType"
                    label="Designation Type"
                    :rules="[v => !!v || 'Designation type is required']"
                    :loading="isEdit && editLoading"
                    :disabled="isEdit && editLoading"
                    required
                ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="staff.salary"
                    label="Salary Amount"
                    :rules="[v => !!v || 'Salary amount is required']"
                    type="number"
                    prefix="৳"
                    :loading="isEdit && editLoading"
                    :disabled="isEdit && editLoading"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="staff.salary_type"
                    :items="salary"
                    label="Salary Type"
                    :rules="[v => !!v || 'Salary type is required']"
                    :loading="isEdit && editLoading"
                    :disabled="isEdit && editLoading"
                    required
                ></v-select>
                </v-col>
              </v-row>

            </v-col>
          </v-row>
        </v-form>
    </v-card>
    <v-form ref="form3" v-model="form3">
      <v-card :class="cardClass">
        <h3 class="mb-5"><v-chip
          class="primary black--text mr-4"
          label
        >2</v-chip>Security</h3>
        <v-row class="mt-n3">
          <v-col cols="12" md="6">
            <v-text-field
              v-model="staff.cell"
              label="Contact No.* (unique)"
              :rules="contactRules"
              type="number"
              prefix="+880"
              :loading="isEdit && editLoading"
              :disabled="isDialogStaff || isEdit && editLoading"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="staff.email"
              label="Email* (unique)"
              :rules="emailRules"
              type="text"
              :loading="isEdit && editLoading"
              :disabled="isDialogStaff || isEdit && editLoading"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-n3" v-if="!isDialogStaff">
          <v-col cols="12" md="6">
            <v-text-field
              v-model="staff.password"
              label="Password"
              :append-icon="show1 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"
              :rules="passwordRules"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="staff.password_confirmation"
              :rules="confirmBranchPasswordRules"
              :append-icon="show2 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              :type="show2 ? 'text' : 'password'"
              @click:append="show2 = !show2"
              label="Confirm Password"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="staff.staff_id"
              label="Staff Unique ID"
              type="text"
              :rules="idRules"
              :loading="isEdit && editLoading"
              :disabled="isDialogStaff || isEdit && editLoading"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" v-if="isDialogStaff">
            <v-select
              v-model="staff.branch_id"
              :items="[branches]"
              item-text="name"
              item-value="id"
              :rules="[v => !!v || 'Branch is required']"
              label="Branch"
              :loading="isEdit && editLoading"
              :disabled="isDialogStaff || isEdit && editLoading"
              required
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" v-else>
            <v-select
              v-model="staff.branch_id"
              :items="branches"
              item-text="name"
              item-value="id"
              :rules="[v => !!v || 'Branch is required']"
              :loading="isEdit && editLoading"
              :disabled="isDialogStaff || isEdit && editLoading"
              label="Branch"
              required
            ></v-select>
          </v-col>
        </v-row>
      </v-card>
      <v-card-actions class="pr-0">
        <v-spacer></v-spacer>
        <v-btn v-if="isDialogStaff" @click="updateStaff" :disabled='!form2 || loading || !changeValue' :loading="loading" class="primary mt-4">
          Save Changes
        </v-btn>
        <v-btn v-if="staffAdd<2 && !isDialogStaff" :disabled='!form2 || !form3 || loading' :loading="loading" @click="addStaff" class="primary mt-4">
          Add Staff
        </v-btn>
      </v-card-actions>
      </v-form>
  </div>
</template>

<script>
import store from '../store'
export default {
  props:["isDialog", "isDialogStaff","branch", "user", "isEdit"],
  data() {
    return {
      cardClass: "mt-6 pa-6 card",
      loading: false,
      editLoading: true,
      form2: false,
      form3: false,
      step: null,
      branches: [],
      change: null,
      changeValue: false,
      currentStaff: {},
      staff: {
        name: "",
        email: "",
        cell: "",
        address: "",
        password: "",
        password_confirmation: "",
        branch_id: null,
        role_name: "",
        staff_id: "",
        designation: "",
        designation_type: "",
        pos_status: "",
        flagged: "",
        status: "",
        salary_type: "",
        salary: "",
      },
      show1: false,
      show2: false,
      staffAdd: 0,
      role: ['Manager', 'Cashier'],
      designationType: ['Waiter', 'Cashier', 'Cook', 'Manager', 'Accounts', 'Product Developer', 'Business Developer', 'Service', 'Delivery', 'Procurement', 'Others'],
      salary: ['Hourly','Monthly'],

      posSwitch: true,
      employeeSwitch: false,
      flagSwitch: false,

      nameRules: [v => !!v || 'Name is required'],
      addressRules: [v => !!v || 'Address is required'],
      idRules: [v => !!v || 'ID is required'],
      contactRules: [v => !!v || 'Contact No is required', v => (v && v.length == 10) || 'Must be 10 Characters'],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        (v) => !!v || 'Please type password.',
        (v) => (v && v.length >= 6) || 'minimum 6 characters',
      ],
      confirmBranchPasswordRules: [
        v => !!v || 'type confirm password',
        v => v === this.staff.password|| 'The password confirmation does not match'
      ],
    }
  },

  watch: {
    user() {
      this.$refs.form2.reset()
      this.$refs.form3.reset()
      this.editLoading = true
      // console.log(this.user)
      // console.log('editloading',this.editLoading)
      this.loadUserDetails()
    },
    staff: {
      handler(val){
        this.change+=1
        if(this.change==1) {
          this.currentStaff = Object.assign({}, val)
          // console.log('disable check', this.loading, this.changeValue)
        } else {
          this.changeValue = JSON.stringify(this.currentStaff) !== JSON.stringify(val)
        }
      },
      deep: true
    },
    posSwitch() {
      if(this.currentStaff.pos_status!==this.posSwitch) {
        this.changeValue = true
      } else {
        this.changeValue = false
      }
    },
    employeeSwitch() {
      if(this.currentStaff.status!==this.employeeSwitch) {
        this.changeValue = true
      } else {
        this.changeValue = false
      }
    },
    flagSwitch() {
      if(this.currentStaff.flagged!==this.flagSwitch) {
        this.changeValue = true
      } else {
        this.changeValue = false
      }
    }
  },

  created() {
    // console.log('created- store theke tenant slug', store.getters.TenantSlug)

    if(this.isDialog) {
      this.branches = this.branch
      var parsedBranch = JSON.parse(JSON.stringify(this.branch))
      // console.log('branches',this.branches)
      this.staff.branch_id = parsedBranch[0].id
    } else if(this.isDialogStaff) {
      // console.log('dialog staff create', this.user)
      this.loadUserDetails()

    } else {
      store.dispatch('getBranch', store.getters.TenantSlug)
      .then(res => {
        // console.log('staffForm branch res', res.data.data.branches.branches)
        this.branches = res.data.data.branches.branches
        // console.log('all branches in User form', this.branches)
      })
    }
  },

  methods: {
    updateStaff() {
      this.posSwitch == true ? this.staff.pos_status = 'on' : this.staff.pos_status = 'off'
      this.employeeSwitch == true ? this.staff.status = 'on' : this.staff.status = 'off'
      this.flagSwitch == true ? this.staff.flagged = 'on' : this.staff.flagged = 'off'

      if(this.$refs.form2.validate() && this.$refs.form3.validate()) {
        this.form2 = false
        this.form3 = false
        this.loading = true
        let staffPayload = {}
        Object.assign(staffPayload, this.staff)
        staffPayload.cell = '0' + this.staff.cell

        // console.log('updated', staffPayload)

        const payload = [
          {
            tenantSlug: store.getters.TenantSlug,
            userId: this.user.id,
          },
          staffPayload
        ]

        store.dispatch('updateStaff', payload)
        .then((res) => {
           if (res.status === 422) {
            this.loading = false
            this.changeValue = true
            const payload = {
              snackbarColor: "#FF5252",
              snackbarText: res.data.message
            }
            this.$emit('staffEditInfo',payload)
            return
           }
          this.loading = false
          this.changeValue = false
          // console.log(res)
          const payload = {
            snackbarColor: "#31A274",
            snackbarText: "Staff successfully updated",
          }
          this.$emit('staffEditInfo',payload)
          // this.staff = Object.assign({}, this.currentStaff)
          this.$refs.form2.reset()
          this.$refs.form3.reset()
          }).catch(err => {
            console.log(err)
            this.loading = false
            this.changeValue = true
            const payload = {
              snackbarColor: "#FF5252",
              snackbarText: "Something Went Wrong!"
            }
            this.$emit('staffEditInfo',payload)
          })
      }

    },
    loadUserDetails() {
      const payload = {
        tenantSlug: store.getters.TenantSlug,
        userId: this.user.id,
      }
      store.dispatch('getStaffDetails', payload)
      .then(res => {
        // console.log('details user -- ',res.data.data.staff.branches)
        // console.log('details new', res.data.data.staff)
        this.branches = res.data.data.staff.branches[0]

        this.staff.name = res.data.data.staff.name
        this.staff.email = res.data.data.staff.email
        this.staff.cell = res.data.data.staff.phone_number.slice(1)
        this.staff.address = res.data.data.staff.address
        // this.staff.password = res.data.data.staff
        // this.staff.password_confirmation = res.data.data.staff
        this.staff.branch_id = res.data.data.staff.branches[0].id
        this.staff.role_name = res.data.data.staff.role
        this.staff.staff_id = res.data.data.staff.staff_id
        this.staff.designation = res.data.data.staff.designation
        this.staff.designation_type = res.data.data.staff.designation_type
        this.staff.salary = res.data.data.staff.salary
        this.staff.salary_type = res.data.data.staff.salary_type

        res.data.data.staff.pos_status == "on" ? this.staff.pos_status = true : this.staff.pos_status = false
        res.data.data.staff.flagged == "on" ? this.staff.flagged = true : this.staff.flagged = false
        res.data.data.staff.status == "on" ? this.staff.status = true : this.staff.status = false

        res.data.data.staff.pos_status == "on" ? this.posSwitch = true : this.posSwitch = false
        res.data.data.staff.flagged == "on" ? this.flagSwitch = true : this.flagSwitch = false
        res.data.data.staff.status == "on" ? this.employeeSwitch = true : this.employeeSwitch = false

        // console.log(this.staff.pos_status, this.staff.status, this.staff.flagged)
        this.editLoading = false

        this.change = null
        this.changeValue = false
      })
    },
    addStaff() {
      this.posSwitch == true ? this.staff.pos_status = 'on' : this.staff.pos_status = 'off'
      this.employeeSwitch == true ? this.staff.status = 'on' : this.staff.status = 'off'
      this.flagSwitch == true ? this.staff.flagged = 'on' : this.staff.flagged = 'off'

      if(this.$refs.form2.validate() && this.$refs.form3.validate()) {
        this.form2 = false
        this.form3 = false
        this.loading = true
        let staffPayload = {}
        Object.assign(staffPayload, this.staff)
        staffPayload.cell = '0' + this.staff.cell

        store.dispatch('createStaff', staffPayload)
        .then((res) => {
          if (res.status > 205) {
            // console.log(res.data.status)
            this.form2 = true
            this.form3 = true
            this.loading = false
            if(this.isDialog) {
              if(res.status === 500) {
                const payload = {
                  snackbarColor: "#FF5252",
                  snackbarText: "Something Went Wrong",
                  status: 'error',
                  dialog: true
                }
                this.$emit('staffInfo', payload)
              } else if (res.status === 422) {
                if(res.data.errors.cell && res.data.errors.email) {
                  const payload = {
                    snackbarColor: "#FF5252",
                    snackbarText: "Email and Phone no. should be unique",
                    status: 'error',
                    dialog: true
                  }
                  this.$emit('staffInfo', payload)
                } else if(res.data.errors.cell) {
                  const payload = {
                    snackbarColor: "#FF5252",
                    snackbarText: "Phone no should be unique",
                    status: 'error',
                    dialog: true
                  }
                  this.$emit('staffInfo', payload)
                } else {
                  const payload = {
                    snackbarColor: "#FF5252",
                    snackbarText: "Email should be unique",
                    status: 'error',
                    dialog: true
                  }
                  this.$emit('staffInfo', payload)
                }
              } else {
                const payload = {
                  snackbarColor: "#FF5252",
                  snackbarText: res.data.status,
                  status: 'error',
                  dialog: true
                }
                this.$emit('staffInfo', payload)
              }
            } else {
              this.step = null
              if(res.status === 500) {
                const payload = {
                  snackbarText: "Something Went Wrong",
                  step: null
                }
                this.$emit('stepNum', payload)
              } else if (res.status === 422) {
                if(res.data.errors.cell && res.data.errors.email) {
                  const payload = {
                    snackbarText: "Email and Phone no. should be unique",
                    step: null
                  }
                  this.$emit('stepNum', payload)
                } else if (res.data.errors.cell) {
                  const payload = {
                    snackbarText: "Phone no. should be unique",
                    step: null
                  }
                  this.$emit('stepNum', payload)
                } else {
                  const payload = {
                    snackbarText: "Email should be unique",
                    step: null
                  }
                  this.$emit('stepNum', payload)
                }
              } else {
                const payload = {
                  snackbarText: res.data.status,
                  step: null
                }
                this.$emit('stepNum', payload)
              }
            }
          } else {
            this.role.splice(this.role.findIndex(item => item === staffPayload.role_name), 1)
            this.loading = false
            this.staffAdd++
            this.posSwitch = true
            this.employeeSwitch = false
            this.flagSwitch = false
            if(this.isDialog) {
            const payload = {
              snackbarColor: "#31A274",
              snackbarText: "Staff successfully added",
              dialog: false,
            }
            this.$emit('staffInfo', payload)
          } else {
            // console.log(this.staffAdd)
            // back to first page
            if(this.staffAdd > 1) {
              this.selectLogo = false
              this.step = 1
              const payload = {
                step: 1,
                snackbarText: res.data.message,
                back: 1
              }
              this.$emit('stepNum', payload)
            } else {
              this.step = 3
              const payload = {
                step: 3,
                snackbarText: res.data.message
              }
              this.$emit('stepNum', payload)
              }
          }
          this.$refs.form2.reset()
          this.$refs.form3.reset()
          }
        })
      }
    },
  }
}
</script>

<style>

</style>
